import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from './../components/layout';
import Seo from './../components/seo';

const NotFoundPage = () => {
  return <>
    <Layout>
      <Seo title="404: Not found" />
      <h1>404: Page not found</h1>
      <p>This page does not exist</p>
      <p><Link to="/">Click here</Link> to return home.</p>
    </Layout>
  </>;
}

export default NotFoundPage;